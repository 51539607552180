
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
	name: 'SocialMediaRecruitingSection'
})
export default class SocialMediaRecruitingSection extends Vue {
	@Prop({ type: Boolean, default: false }) readonly hideButton?: boolean;

	socials = [
		{
			image: 'meta.svg',
			alt: 'Meta'
		},
		{
			image: 'insta-fb.svg',
			alt: 'Instagram & Facebook'
		},
		{
			image: 'tiktok.svg',
			alt: 'Tiktok'
		},
		{ image: 'snapchat-logo.svg', alt: 'Snapchat' }
	];

	trackingAndRedirect() {
		this.$trackWebsiteEvent('go_to_pricing_page', { pageElement: 'social_media_recruiting' });
		this.$emit('clicked-cta');
	}
}
